import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/_services'; // Assuming you have an AuthService to manage login/logout

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if it's an invalid token error
        if (error.status === 401 && error.error.error_description.includes('The token expired')) {
          // Call your logout or refresh logic
          this.authService.logout(); // For example, logging out the user
        }
        return throwError(() => error);
      })
    );
  }
}