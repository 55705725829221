// export class TravelAdvice {
//     essentialInfo?: EssentialInfo[];
// }

export class EssentialInfo{
    title?:string;
    notes?:string;
    countryCode?: string;
    countryName?: string;

}