import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { config } from '@shared/config';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { commonResponse } from '@app/_models';
import { HeaderConstants } from '@app/constant/headers';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  itineraryNo?: number;
  accountCode: any;
  userid: any;
  userData: any;

  constructor(private http: HttpClient, private hConst: HeaderConstants) {
    const userDataString = sessionStorage.getItem('user');
    this.userData = userDataString ? JSON.parse(userDataString) : null;
    this.itineraryNo = this.userData?.itineraryNo;
    this.accountCode = this.userData?.accountCode;
    this.userid = this.userData?.userId;
  }

  getDashboardDetails(accountCode: any, itineraryNo?: any) {
    const userDataString = sessionStorage.getItem('user');
    const userObj = userDataString ? JSON.parse(userDataString) : null;
    let url: any;
    if (itineraryNo && itineraryNo != 0) {
      url = `${environment.apiUrl}/${environment.dashboardDetails}/${accountCode}?itineraryNo=${itineraryNo}`;
    } else {
      url = `${environment.apiUrl}/${environment.dashboardDetails}/${accountCode}`;
    }

    return this.http
      .get<commonResponse>(url).pipe(
        map((response) => {
          const client = response?.responseData?.client;
          const itineraryDetails = response?.responseData?.itineraryDetails;
          const mergedObject = { ...client, ...itineraryDetails };
          const userInfo = Object.assign({}, userObj, mergedObject);
          sessionStorage.setItem(
            'user',
            JSON.stringify(userInfo)
          );
          return response;
        })
      );

  }

  getDocumentsDetailsSer() {
    return this.http
      .get<commonResponse>(
        `${environment.documentDetailsDoc}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDocumentsDetails(itineraryNo: any) {

    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.documentDetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getHeaderDetails() {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.headerDetails}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFooterDetails() {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.footerDetails}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}
