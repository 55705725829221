import { Image } from './image';

export class Footer {
  companyContactNo?: string;
  footerText?: string;
  companyLink?: string;
  aboutUsLink?: string;
  aboutUsText?: string;
  imageList?: Image[];
  address?: string;
  companyEmail?: string;
}

export class TravelExpertDetail {
  expertName?: string;
  designation?: string;
  email?: string;
  telephone?: string;
}