export class Header {
    companyLogo?: string;
    companyCode?: string;
    companyContactNo?: string;
    companyEmail?: string;
    branchCode?: string;
    headerText?: string;
    contactUsLink?: string;
    contactUsText?: string;
    hideWhatsappLink?: string;
    hideFacebookLink?: string;
}