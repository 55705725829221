import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  logout() {

    const user = JSON.parse(sessionStorage.getItem('user')!) || [];
    let branchCode = user?.branchCode;

    if (!branchCode) {
      branchCode = 'DK';
    }

    sessionStorage.removeItem('dictionary');
    if (branchCode) {
      sessionStorage.clear();
      sessionStorage.clear();
    }

    // Navigate to login page
    this.router.navigate(['/account/login/' + branchCode]);

  }
}
