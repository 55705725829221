export class User {
    id?: string;
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    userId?: any;
    accountCode?: any;
    itineraryno?: any;

}
