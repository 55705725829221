import { DefaultUrlSerializer, UrlSerializer, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';

export class LowerCaseUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Parse the URL into a UrlTree
    const urlTree = this.defaultUrlSerializer.parse(url);
    const segmentGroup: any = urlTree.root.children[PRIMARY_OUTLET];

    if (segmentGroup) {
      // Iterate through each segment and apply lowercase, except for "DK"
      segmentGroup.segments = segmentGroup.segments.map(segment => {
        return (segment.path === 'DK' || segment.path === 'NO' || segment.path === 'EN') ? segment : { ...segment, path: segment.path.toLowerCase() };
      });
    }

    // Lowercase query parameters as well
    urlTree.queryParams = Object.keys(urlTree.queryParams).reduce((acc, key) => {
      acc[key.toLowerCase()] = urlTree.queryParams[key];
      return acc;
    }, {} as { [key: string]: any });

    return urlTree;
  }

  serialize(tree: UrlTree): string {
    return this.defaultUrlSerializer.serialize(tree);
  }
}