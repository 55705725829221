import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class HeaderConstants {
    public getHeaders(): HttpHeaders {
        const userSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('user')!));
        const user = userSubject.asObservable();
        const token =userSubject.value?.token;
        var headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return headers;
    }

    public postHeaders(): HttpHeaders {
        const userSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('user')!));
        const user = userSubject.asObservable();
        const token =userSubject.value?.token;
        var headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return headers;
    }
}
