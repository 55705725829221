import { Image } from "./image";

export class ItineraryDetail{
    itineraryName?:string;
    headingFromToDateTxt?:string;
    itineraryDepDate?:string;
    itineraryArrDate?:string;
    noOfDays?:string;
    noOfNights?:string;
    itineraryText?:string;
    bannerImages?:Image[];
    dayWiseItinerary?:DayWiseItinerary[];
}

export class DayWiseItinerary{
    displayDayNo?:string;
    depDateTxt?:string;
    depDate?:Date;
    arrDate?:Date;
    daywiseImages?:Image[];
    componentDetails?:ComponentDetails[];
}

export class ComponentDetails{
    componentCode?:string;
    componentIcon?:string;
    componentText?:string;
    componentType?: string;
    fromPlace?:string;
    toPlace?:string;
    isOwnArrangement?:boolean=false;
    classOfTravel?:string;
    city?:string;
    country?:string;
    header?:string;
    fromAirport?:string;
    toAirport?:string;
    airlineName?:string;
    flightNo?:string;
    depTime?:string;
    arrTime?:string;
    vehicle?:string;
    pickUpPoint?:string;
    dropOfPoint?:string;
    hotelName?:string;
    hotelRoomType?:string;
    mealBasis?:string;
    occType?:string;
    noOfRooms?:string;
    noOfNights?:string;
    componentDepDate?: string;
    componentDepDateStr?:string;
    componentArrDate?:string;
    componentArrDateStr?:string;
}