import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private localeSubject = new BehaviorSubject<any>('en');
  currentLocale$ = this.localeSubject.asObservable();

  setLocale(locale: string) {
    this.localeSubject.next(locale);
  }

  getLocale(): string {
    return this.localeSubject.value;
  }
}