export class FlightDetails {
  flightStaticText?: string;
  importantNotes?: string;
  flights?: Flights[];
  airlinebaggage?: Airlinebaggage[];
  travelClients?: TravelClients[];
  pnrNo: any;

}

export class Flights {
  bkgNo?: number;
  quoteNo?: number;
  departureDate?: string;
  arrivalDate?: string;
  departureTime?: string;
  arrivalTime?: string;
  airlineName?: string;
  coponentTypeCode?: string;
  ownArrangementind?: string;
  vehicleNo?: string;
  flightShares?: string;
  frAirportName?: string;
  toAirportName?: string;
  departureTerminal?: string;
  arrivalTerminal?: string;
  via1?: string;
  checkInTime?: string;
  classOfTravel?: string;
  seatNumbers?: string;
  dayIndicatorText?: string;
  frCityName?: string;
  toCityName?: string;
  departureDateStr?: string;
  travelClients?: TravelClients[];
  departureTimeStr?: any;
  arrivalTimeStr?: any;
  totalTimeStr?: string;
  pnrNo?: any;
  operatedBy?: string;
  displayDayNo?: string;
}

export class Airlinebaggage {
  seqNo?: number;
  dayNo?: string;
  airlineCode?: string;
  airlineName?: string;
  classOfTravelCode?: string;
  classOfTravelDesc?: string;
  baggageAllowanceNotes?: string;
  baggageAllowance?: string;
  vehicle?: string;
  depDate?: string;
  pnrNo?: any;
}

export class TravelClients {
  title?: string;
  fName?: string;
  mName?: string;
  lName?: string;
  eTicketNo?: string;
  clientName?: string;
  titlePP?: string;
  fNamePP?: string;
  mNamePP?: string;
  lNamePP?: string;
  clientNamePP?: string;
  seatNo?: string;
  clientPNR?: string;
}
