import { Image } from "./image";

export class Accommodation {
    bkgNo?: number;
    quoteNo?: number;
    itineraryNo?: number;
    printInd?: boolean;
    showHotelAddrContactNoInd?: boolean;
    hotelList?: HotelList[];
}

export class HotelList {
    hotelCode?: number;
    hotelDescription?: string;
    hotelName?: string;
    hotelTelephoneNo?: string;
    hotelAddress?: string;
    cityLng?: string;
    cityLat?: string;
    cityCode?: string;
    cityName?: string;
    countryCode?: string;
    countryName?: string;
    hotelRating?: string;
    referenceCode?: string;
    hotelComponent?: HotelComponent[];
    hotelImageList?: Image[];

}

export class HotelComponent {
    dayNo?: string;
    fromDate?: string;
    toDate?: string;
    mealBasis?: string;
    roomType?: string;
    noOfRooms?: string;
    noOfNights?: string;
    cpntSeqNo?: number;
    occType?: string;
}