import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderConstants } from '@app/constant/headers';
import { commonResponse } from '@app/_models';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { AccountService } from '@app/_services';

@Injectable({
  providedIn: 'root',
})
export class YourTravelService {
  itineraryNo: any;
  accountCode: any;
  userId: any;

  constructor(private http: HttpClient, private hConst: HeaderConstants) {


    const userDataString = sessionStorage.getItem('user');
    let userData = userDataString ? JSON.parse(userDataString) : null;
    this.accountCode = userData?.accountCode;
    this.itineraryNo = userData?.itineraryNo;
    this.userId = userData?.userId;
  }


  getConfirmedClientTrips(accountCode) {

    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.clientTrips}/${accountCode}/${this.userId}/confirmed`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getProposedClientTrips(accountCode) {

    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.clientTrips}/${accountCode}/${this.userId}/quote`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPastClientTrips(accountCode) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.clientTrips}/${accountCode}/${this.userId}/past`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
