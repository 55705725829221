import { Image } from "./image";
export class ItineraryList{
    buttonText?: string;
    staticText?: string;
    pageTitle?:string;
    tripList?:Itinerary[]
}

export class Itinerary {
    itineraryNo?: number;
    bkgNo?: number;
    quoteNo?: number;
    countryName?:string;
    itineraryName?: string;
    departureDate?: Date;
    departureDateStr?: string;
    arrivalDate?: Date;
    arrivalDateStr?: string;
    noOfDays?: number;
    displayNoOfDays?: string;
    isBooking?: boolean;
    isConfirmedTrip?: boolean;
    isQuoteTrip?: boolean;
    isPastTrip?: boolean;
    clientName?:string;
    tripText?:string;
    image?:Image[];
    noOfRooms?: string;
    componentDepDate?: string;
    componentDepDateStr?:string;
    componentArrDate?:string;
    componentArrDateStr?:string;
}