// footer-visibility.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterVisibilityService {
  private showFooterSource = new BehaviorSubject<boolean>(true);
  showFooter$ = this.showFooterSource.asObservable();

  updateFooterVisibility(show: boolean) {
    this.showFooterSource.next(show);
  }
}