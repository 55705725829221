import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  // private apiUrl = 'http://localhost:3000/generate-pdf';
  // private apiUrl = `${environment.apiUrlPdf}/generate-pdf`;
  // private apiUrl = 'https://my.blixentours.com/mydhruvpdf/generate-pdf';


  constructor(private http: HttpClient) { }

  generatePdf(url: any, config: any, currentUrl: any): Observable<Blob> {
    return this.http.post(`${environment.pdfApiUrl}/${environment.downloadPdf}`, { url, config, currentUrl }, { responseType: 'blob' });
  }
}