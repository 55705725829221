import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonResponse } from '@app/_models';
import { HeaderConstants } from '@app/constant/headers';
import { environment } from '@environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(private http: HttpClient, private hConst: HeaderConstants) {

    const userDataString = sessionStorage.getItem('user');
    let userData = userDataString ? JSON.parse(userDataString) : null;

  }

  getDictionaryDetails() {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.dictionary}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
